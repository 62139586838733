import React, { useEffect } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { useColorPicker, RGBAColor } from '../hooks/useColorPicker';
import { useSticker } from '../context/StickerContext';
import { Pipette } from 'lucide-react';
import { Button } from './ui/button';
import { useEyeDropper } from '../hooks/useEyeDropper';
import { useWindow } from '../hooks/useWindow';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

export default function ColorPicker({ color, onChange }: ColorPickerProps) {
  const { canvas } = useSticker();
  const { isMobile } = useWindow();
  const {
    color: rgbaColor,
    cmykValues,
    handleColorChange,
    handleCMYKChange,
    getRgbaString
  } = useColorPicker(color);

  const { isActive, startEyeDropper, stopEyeDropper, handleEyeDropper } = useEyeDropper(canvas);

  const handleRGBAChange = (newColor: RGBAColor) => {
    const hexColor = handleColorChange(newColor);
    onChange(hexColor);
  };

  const handleCMYKUpdate = (key: keyof typeof cmykValues, value: number) => {
    const hexColor = handleCMYKChange(key, value);
    onChange(hexColor);
  };

  // Set up eyedropper event handlers
  useEffect(() => {
    if (isActive) {
      const cleanup = handleEyeDropper((pickedColor) => {
        onChange(pickedColor);
      });
      return cleanup;
    }
  }, [isActive, handleEyeDropper, onChange]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <Label>Background Color</Label>
        {!isMobile && (
          <Button
            variant="outline"
            size="sm"
            className={isActive ? 'bg-blue-50 border-blue-500' : ''}
            onClick={() => {
              if (isActive) {
                stopEyeDropper();
              } else {
                startEyeDropper();
              }
            }}
          >
            <Pipette className="w-4 h-4 mr-2" />
            {isActive ? 'Cancel' : 'Pick Color'}
          </Button>
        )}
      </div>
      <Tabs defaultValue="picker" className="w-full">
        <TabsList className="w-full grid grid-cols-3">
          <TabsTrigger value="picker">Color Picker</TabsTrigger>
          <TabsTrigger value="rgb">RGB</TabsTrigger>
          <TabsTrigger value="cmyk">CMYK</TabsTrigger>
        </TabsList>

        <TabsContent value="picker" className="flex justify-center pt-4">
          <RgbaColorPicker color={rgbaColor} onChange={handleRGBAChange} />
        </TabsContent>

        <TabsContent value="rgb" className="space-y-4 pt-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label>Red</Label>
              <Input
                type="number"
                min="0"
                max="255"
                value={rgbaColor.r}
                onChange={(e) => {
                  const newColor = { ...rgbaColor, r: Number(e.target.value) };
                  const hexColor = handleColorChange(newColor);
                  onChange(hexColor);
                }}
              />
            </div>
            <div>
              <Label>Green</Label>
              <Input
                type="number"
                min="0"
                max="255"
                value={rgbaColor.g}
                onChange={(e) => {
                  const newColor = { ...rgbaColor, g: Number(e.target.value) };
                  const hexColor = handleColorChange(newColor);
                  onChange(hexColor);
                }}
              />
            </div>
            <div>
              <Label>Blue</Label>
              <Input
                type="number"
                min="0"
                max="255"
                value={rgbaColor.b}
                onChange={(e) => {
                  const newColor = { ...rgbaColor, b: Number(e.target.value) };
                  const hexColor = handleColorChange(newColor);
                  onChange(hexColor);
                }}
              />
            </div>
            <div>
              <Label>Alpha</Label>
              <Input
                type="number"
                min="0"
                max="1"
                step="0.01"
                value={rgbaColor.a}
                onChange={(e) => {
                  const newColor = { ...rgbaColor, a: Number(e.target.value) };
                  const hexColor = handleColorChange(newColor);
                  onChange(hexColor);
                }}
              />
            </div>
          </div>
        </TabsContent>

        <TabsContent value="cmyk" className="space-y-4 pt-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label>Cyan (%)</Label>
              <Input
                type="number"
                min="0"
                max="100"
                value={cmykValues.c}
                onChange={(e) => handleCMYKUpdate('c', Number(e.target.value))}
              />
            </div>
            <div>
              <Label>Magenta (%)</Label>
              <Input
                type="number"
                min="0"
                max="100"
                value={cmykValues.m}
                onChange={(e) => handleCMYKUpdate('m', Number(e.target.value))}
              />
            </div>
            <div>
              <Label>Yellow (%)</Label>
              <Input
                type="number"
                min="0"
                max="100"
                value={cmykValues.y}
                onChange={(e) => handleCMYKUpdate('y', Number(e.target.value))}
              />
            </div>
            <div>
              <Label>Key (Black) (%)</Label>
              <Input
                type="number"
                min="0"
                max="100"
                value={cmykValues.k}
                onChange={(e) => handleCMYKUpdate('k', Number(e.target.value))}
              />
            </div>
          </div>
        </TabsContent>
      </Tabs>

      <div 
        className="w-full h-12 rounded-md border"
        style={{ backgroundColor: getRgbaString() }}
      />
    </div>
  );
}