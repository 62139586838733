import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image, X, Trash2, Upload } from 'lucide-react';
import { useSticker } from '../context/StickerContext';
import { Label } from './ui/label';
import DimensionInput from './DimensionInput';
import SizeConstraintDialog from './SizeConstraintDialog';
import { useImageUpload } from '../hooks/useImageUpload';
import { useShapes } from '../hooks/useShapes';
import { useCanvasSize } from '../hooks/useCanvasSize';
import EditableTitle from './EditableTitle';
import CompactColorPicker from './CompactColorPicker';
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { cn } from '../lib/utils';

interface SidebarProps {
  onClose?: () => void;
}

export default function Sidebar({ onClose }: SidebarProps) {
  const { 
    size, 
    updateSize,
    updateCanvasShape,
    addImage,
    backgroundColor,
    updateBackgroundColor,
    canvasShape,
    resetCanvas
  } = useSticker();

  const [showSizeDialog, setShowSizeDialog] = useState(false);
  const [uploadPopoverOpen, setUploadPopoverOpen] = useState(false);

  const { handleImageUpload } = useImageUpload();
  const { shapes, handleShapeChange } = useShapes();
  const { 
    showSizeDialog: canvasShowSizeDialog, 
    setShowSizeDialog: setCanvasShowSizeDialog, 
    handleSizeChange,
    getMaxSize 
  } = useCanvasSize();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.svg']
    },
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles[0]) {
        await handleImageUpload(acceptedFiles[0]);
        setUploadPopoverOpen(false);
      }
    },
  });

  return (
    <div className="w-80 bg-white border-l border-gray-200 flex flex-col h-full">
      <div className="flex items-center justify-between p-4 border-b border-gray-200">
        <EditableTitle />
        {onClose && (
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        )}
      </div>

      <div className="flex-1 p-4">
        <div className="space-y-4">
          {/* Upload Section - Compact */}
          <div className="space-y-2">
            <Label>Upload Image</Label>
            <PopoverPrimitive.Root open={uploadPopoverOpen} onOpenChange={setUploadPopoverOpen}>
              <PopoverPrimitive.Trigger asChild>
                <button className="w-full flex items-center justify-between p-2 rounded-md border border-gray-200 hover:border-gray-300 transition-colors">
                  <span className="text-sm text-gray-700">Choose an image</span>
                  <Upload className="w-4 h-4 text-gray-500" />
                </button>
              </PopoverPrimitive.Trigger>
              <PopoverPrimitive.Portal>
                <PopoverPrimitive.Content
                  align="start"
                  className={cn(
                    "z-50 w-72 rounded-md border bg-white p-4 shadow-md outline-none",
                    "data-[state=open]:animate-in data-[state=closed]:animate-out",
                    "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
                    "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
                    "data-[side=bottom]:slide-in-from-top-2",
                    "data-[side=left]:slide-in-from-right-2",
                    "data-[side=right]:slide-in-from-left-2",
                    "data-[side=top]:slide-in-from-bottom-2"
                  )}
                >
                  <div
                    {...getRootProps()}
                    className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center hover:border-gray-400 cursor-pointer"
                  >
                    <input {...getInputProps()} />
                    <Image className="w-6 h-6 mx-auto mb-2 text-gray-400" />
                    <p className="text-sm text-gray-500">
                      Drag & drop or click to upload
                    </p>
                    <p className="text-xs text-gray-400 mt-1">
                      Supports PNG, JPG, JPEG, SVG
                    </p>
                  </div>
                </PopoverPrimitive.Content>
              </PopoverPrimitive.Portal>
            </PopoverPrimitive.Root>
          </div>

          {/* Size Section */}
          <div className="space-y-2">
            <Label>Size (inches)</Label>
            <div className="grid grid-cols-2 gap-4">
              <DimensionInput
                value={size.width}
                onChange={(value) => updateSize('width', value)}
                min={1}
                max={getMaxSize().maxWidth}
                step={1}
                label="Width"
              />
              <DimensionInput
                value={size.height}
                onChange={(value) => updateSize('height', value)}
                min={1}
                max={getMaxSize().maxHeight}
                step={1}
                label="Height"
              />
            </div>
          </div>

          {/* Shape Section */}
          <div className="space-y-2">
            <Label>Shape</Label>
            <div className="grid grid-cols-4 gap-2">
              {shapes.map((shape) => {
                const isSelected = shape.id === canvasShape;
                return (
                  <button
                    key={shape.id}
                    onClick={() => handleShapeChange(shape.id)}
                    className={`p-2 rounded-lg border transition-all duration-200 flex flex-col items-center ${
                      isSelected 
                        ? 'border-blue-500 bg-blue-50' 
                        : 'border-gray-200 hover:border-blue-500 hover:bg-blue-50'
                    }`}
                    title={shape.id.charAt(0).toUpperCase() + shape.id.slice(1)}
                  >
                    <shape.Preview className={`w-5 h-5 ${
                      isSelected ? 'text-blue-500' : 'text-gray-600 group-hover:text-blue-500'
                    }`} />
                    <span className={`text-xs mt-1 ${
                      isSelected ? 'text-blue-500' : 'text-gray-600 group-hover:text-blue-500'
                    } capitalize`}>
                      {shape.id}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>

          {/* Color Picker - Compact */}
          <CompactColorPicker
            color={backgroundColor}
            onChange={updateBackgroundColor}
          />
        </div>
      </div>

      {/* Reset Button */}
      <div className="p-4 border-t border-gray-200">
        <button
          onClick={resetCanvas}
          className="w-full flex items-center justify-center gap-2 p-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md border border-red-200 hover:border-red-300 transition-colors"
        >
          <Trash2 className="w-4 h-4" />
          <span>Reset Canvas</span>
        </button>
      </div>

      <SizeConstraintDialog 
        isOpen={canvasShowSizeDialog}
        onClose={() => setCanvasShowSizeDialog(false)}
      />
    </div>
  );
}