import React, { useState, useEffect } from 'react';
import * as PopoverPrimitive from "@radix-ui/react-popover";
import ColorPicker from './ColorPicker';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { Palette } from 'lucide-react';
import { cn } from '../lib/utils';

interface CompactColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

export default function CompactColorPicker({ color, onChange }: CompactColorPickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [hexValue, setHexValue] = useState(color.toUpperCase());

  useEffect(() => {
    setHexValue(color.toUpperCase());
  }, [color]);

  const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHexValue(value);

    // Only update the actual color if it's a valid hex code
    if (/^#[0-9A-F]{6}$/i.test(value)) {
      onChange(value);
    }
  };

  const handleHexBlur = () => {
    // If the hex value is invalid when leaving the input, reset it
    if (!/^#[0-9A-F]{6}$/i.test(hexValue)) {
      setHexValue(color.toUpperCase());
    }
  };

  const handleHexKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!/^#[0-9A-F]{6}$/i.test(hexValue)) {
        setHexValue(color.toUpperCase());
      }
      e.currentTarget.blur();
    }
  };

  return (
    <div className="space-y-2">
      <Label>Background Color</Label>
      <PopoverPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
        <PopoverPrimitive.Trigger asChild>
          <button
            className="w-full flex items-center justify-between p-2 rounded-md border border-gray-200 hover:border-gray-300 transition-colors"
          >
            <div className="flex items-center gap-2">
              <div
                className="w-6 h-6 rounded-md border border-gray-200"
                style={{ backgroundColor: color }}
              />
              <Input
                value={hexValue}
                onChange={handleHexChange}
                onBlur={handleHexBlur}
                onKeyDown={handleHexKeyDown}
                className="w-24 h-7 px-2 text-sm"
                onClick={(e) => e.stopPropagation()}
                placeholder="#000000"
                spellCheck={false}
              />
            </div>
            <Palette className="w-4 h-4 text-gray-500" />
          </button>
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            align="start"
            className={cn(
              "z-50 w-auto rounded-md border bg-white p-4 shadow-md outline-none",
              "data-[state=open]:animate-in data-[state=closed]:animate-out",
              "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
              "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
              "data-[side=bottom]:slide-in-from-top-2",
              "data-[side=left]:slide-in-from-right-2",
              "data-[side=right]:slide-in-from-left-2",
              "data-[side=top]:slide-in-from-bottom-2"
            )}
          >
            <ColorPicker color={color} onChange={onChange} />
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>
    </div>
  );
}
