import { useState, useEffect } from 'react';

export interface CustomerDetails {
  email: string;
  phone: string;
  shippingAddress: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  billingAddress: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
}

const STORAGE_KEY = 'customerDetails';

export function useCustomerDetails() {
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>(() => {
    const savedDetails = localStorage.getItem(STORAGE_KEY);
    if (savedDetails) {
      try {
        return JSON.parse(savedDetails);
      } catch (e) {
        console.error('Error parsing saved customer details:', e);
        return getEmptyCustomerDetails();
      }
    }
    return getEmptyCustomerDetails();
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(customerDetails));
  }, [customerDetails]);

  const updateCustomerDetails = (details: Partial<CustomerDetails>) => {
    setCustomerDetails(prev => ({
      ...prev,
      ...details,
    }));
  };

  const updateShippingAddress = (address: Partial<CustomerDetails['shippingAddress']>) => {
    setCustomerDetails(prev => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ...address,
      },
    }));
  };

  const updateBillingAddress = (address: Partial<CustomerDetails['billingAddress']>) => {
    setCustomerDetails(prev => ({
      ...prev,
      billingAddress: {
        ...prev.billingAddress,
        ...address,
      },
    }));
  };

  const clearCustomerDetails = () => {
    localStorage.removeItem(STORAGE_KEY);
    setCustomerDetails(getEmptyCustomerDetails());
  };

  return {
    customerDetails,
    updateCustomerDetails,
    updateShippingAddress,
    updateBillingAddress,
    clearCustomerDetails,
  };
}

function getEmptyCustomerDetails(): CustomerDetails {
  return {
    email: '',
    phone: '',
    shippingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
  };
}
