import { useEffect, useState } from 'react';
import { CheckCircle } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

interface OrderDetails {
  orderId: string;
  customerDetails: {
    name: string;
    email: string;
    phone?: string;
  };
  orderDetails: {
    quantity: number;
    size: string;
    shape: string;
    finish: string;
    price: number;
  };
  imageUrl?: string;
}

export default function Success() {
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!sessionId) return;
      
      try {
        const response = await fetch(`${import.meta.env.VITE_WORKER_URL}/order-details?session_id=${sessionId}`);
        if (!response.ok) throw new Error('Failed to fetch order details');
        
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [sessionId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!orderDetails) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-semibold text-gray-900 mb-4">Order Confirmation Unavailable</h1>
          <p className="text-gray-600">Unable to retrieve order details. Please check your email for confirmation.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          {/* Header */}
          <div className="bg-green-500 px-6 py-8 text-center">
            <CheckCircle className="h-16 w-16 text-white mx-auto mb-4" />
            <h1 className="text-3xl font-bold text-white">Order Confirmed!</h1>
            <p className="text-green-100 mt-2">Thank you for your order. We'll start working on your stickers right away!</p>
          </div>

          {/* Order Details */}
          <div className="px-6 py-8">
            <div className="grid grid-cols-1 gap-8">
              {/* Order Number */}
              <div>
                <h2 className="text-lg font-semibold text-gray-900 mb-2">Order Number</h2>
                <p className="text-gray-600 font-mono bg-gray-50 p-3 rounded">{orderDetails.orderId}</p>
              </div>

              {/* Customer Details */}
              <div>
                <h2 className="text-lg font-semibold text-gray-900 mb-2">Customer Details</h2>
                <div className="bg-gray-50 p-4 rounded">
                  <p className="text-gray-600"><span className="font-medium">Name:</span> {orderDetails.customerDetails.name}</p>
                  <p className="text-gray-600"><span className="font-medium">Email:</span> {orderDetails.customerDetails.email}</p>
                  {orderDetails.customerDetails.phone && (
                    <p className="text-gray-600"><span className="font-medium">Phone:</span> {orderDetails.customerDetails.phone}</p>
                  )}
                </div>
              </div>

              {/* Order Summary */}
              <div>
                <h2 className="text-lg font-semibold text-gray-900 mb-2">Order Summary</h2>
                <div className="bg-gray-50 p-4 rounded">
                  <div className="grid grid-cols-2 gap-4">
                    <p className="text-gray-600"><span className="font-medium">Quantity:</span> {orderDetails.orderDetails.quantity}</p>
                    <p className="text-gray-600"><span className="font-medium">Size:</span> {orderDetails.orderDetails.size}</p>
                    <p className="text-gray-600"><span className="font-medium">Shape:</span> {orderDetails.orderDetails.shape}</p>
                    <p className="text-gray-600"><span className="font-medium">Finish:</span> {orderDetails.orderDetails.finish}</p>
                  </div>
                  <div className="mt-4 pt-4 border-t border-gray-200">
                    <p className="text-lg font-semibold text-gray-900">
                      Total: ${(orderDetails.orderDetails.price / 100).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Sticker Preview */}
              {orderDetails.imageUrl && (
                <div>
                  <h2 className="text-lg font-semibold text-gray-900 mb-2">Sticker Preview</h2>
                  <div className="bg-gray-50 p-4 rounded">
                    <img 
                      src={orderDetails.imageUrl} 
                      alt="Sticker Preview" 
                      className="w-full max-w-md mx-auto rounded-lg shadow-sm"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Next Steps */}
            <div className="mt-8 bg-blue-50 p-4 rounded">
              <h2 className="text-lg font-semibold text-blue-900 mb-2">What's Next?</h2>
              <ul className="list-disc list-inside text-blue-800 space-y-2">
                <li>We'll review your design to ensure the best print quality</li>
                <li>You'll receive an email confirmation with these order details</li>
                <li>Production typically takes 3-5 business days</li>
                <li>We'll notify you when your stickers ship</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
