import { useCallback, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { colord } from 'colord';

export function useEyeDropper(canvas: fabric.Canvas | null) {
  const [isActive, setIsActive] = useState(false);
  const originalCursor = useRef<string>('');
  
  const startEyeDropper = useCallback(() => {
    if (!canvas) return;
    
    setIsActive(true);
    originalCursor.current = canvas.defaultCursor || '';
    canvas.defaultCursor = 'crosshair';
    
    // Disable selection and object controls while eyedropper is active
    canvas.selection = false;
    canvas.forEachObject((obj) => {
      obj.selectable = false;
      obj.hoverCursor = 'crosshair';
    });
  }, [canvas]);

  const stopEyeDropper = useCallback(() => {
    if (!canvas) return;
    
    setIsActive(false);
    canvas.defaultCursor = originalCursor.current;
    
    // Re-enable selection and object controls
    canvas.selection = true;
    canvas.forEachObject((obj) => {
      obj.selectable = true;
      obj.hoverCursor = 'move';
    });
  }, [canvas]);

  const getColorFromPoint = useCallback((event: fabric.IEvent): string | null => {
    if (!canvas || !event.e) return null;

    const pointer = canvas.getPointer(event.e);
    const context = canvas.getContext();
    
    // Get pixel data at pointer position
    const imageData = context.getImageData(
      Math.round(pointer.x),
      Math.round(pointer.y),
      1,
      1
    );
    
    if (!imageData) return null;

    // Convert RGB to hex
    const color = colord({
      r: imageData.data[0],
      g: imageData.data[1],
      b: imageData.data[2],
      a: imageData.data[3] / 255
    }).toHex();

    return color;
  }, [canvas]);

  const handleEyeDropper = useCallback((callback: (color: string) => void) => {
    if (!canvas) return;

    const handleMouseMove = (event: fabric.IEvent) => {
      if (!isActive) return;
      
      const color = getColorFromPoint(event);
      if (color) {
        // Update preview or tooltip here if needed
      }
    };

    const handleMouseDown = (event: fabric.IEvent) => {
      if (!isActive) return;
      
      const color = getColorFromPoint(event);
      if (color) {
        callback(color);
        stopEyeDropper();
      }
    };

    canvas.on('mouse:move', handleMouseMove);
    canvas.on('mouse:down', handleMouseDown);

    return () => {
      canvas.off('mouse:move', handleMouseMove);
      canvas.off('mouse:down', handleMouseDown);
    };
  }, [canvas, isActive, getColorFromPoint, stopEyeDropper]);

  return {
    isActive,
    startEyeDropper,
    stopEyeDropper,
    handleEyeDropper
  };
}
